import React, { Component } from 'react';
import './blue-banner.css';

const Bluebanner = (props) => {
  return (
    //   <div className="col span-1-of-3 boxx">
    //     {/* <img class="dcp-img" src="images/design.svg" width=115 height=auto> */}
    //     <img className="dcp-img" src={props.source}></img>
    //     <p className="dcp-para-title">{props.title}</p>
    //     <p className="dcp-para">{props.des}</p>
    //   </div>
    <section className="service-header">
      <p className="innerpage-title">{props.title}</p>

      {/* <img class="service-img" src="images/service.svg" width=720 height=auto> */}
      <img
        className="inner-banner-img"
        src={props.source}
        style={{ maxWidth: props.imgWidth, maxHeight: props.imgHeight }}
      ></img>
    </section>
  );
};

export default Bluebanner;
