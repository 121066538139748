import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import Bluebanner from '../components/blue-banner';
import '../components/blog.css';
import BlogCard from '../components/BlogCard/blog-card';
import ContactBanner from '../components/contact-banner';
import Contact from '../components/contact';
import SEO from '../components/seo';

// import Card from '../components/card';
const ComingSoon = ()=>{
  return(
    <div style={{ display:'flex', justifyContent:'center'}}>
      <h2>Coming Soon!</h2>
    </div>
  )
}

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              title
              author
              date
              topics
              excerpt
              featureImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
    <SEO title="Blog" />
      <Bluebanner
        source={require('../images/blogg.svg')}
        title="Blog"
        imgWidth={357.78}
        imgHeight="auto"
      />
      <section className="blogs">
        <ComingSoon/>
      </section>
      {/* <section className="blog-topics">
        <div className="row">
          <ul className="topics-nav">
            <li className="blog-li">
              <Link to="#">Design</Link>
            </li>
            <li className="blog-li">
              <Link to="#">Technology</Link>
            </li>
          </ul>
        </div>
      </section>
      <section class="blogs">
        <div className="row">
          <p className="read-title">Latest reads</p>
        </div>
        <div className="row">
          <div className="container">
            {data.allMarkdownRemark.edges.map((item) => {
              const {
                title,
                author,
                topics,
                excerpt,
                date,
                featureImage,
              } = item.node.frontmatter;
              return (
                <BlogCard
                  topics={topics}
                  title={title}
                  excerpt={excerpt}
                  source={featureImage.childImageSharp.fluid}
                  date={date}
                  author={author}
                  slug={item.node.fields.slug}
                />
              );
            })}
          </div>
        </div>
      </section> */}
      <section class="contactt">
        <ContactBanner />
        <Contact />
      </section>
    </Layout>
  );
};


export default Blog;
